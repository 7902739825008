body {
  background-color: @layout-body-background;
  font-size: 16px;
  line-height: 1.5;
  color: @text-color;
}

.container {
  width: 100%;
  max-width: 1170px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.hidden {
  display: none !important;
}

.nowrap {
  white-space: nowrap;
}

.break-word {
  word-break: break-word;
}

/* Display LastPass extension icon in inputs where data-lpshow="true" */
div[data-lastpass-icon-root] {
  display: none;
}

input[data-lpshow] {
  + div[data-lastpass-icon-root] {
    display: block;
  }
}

.p-lr-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.p-l-8 {
  padding-left: 8px;
}
.p-t-10 {
  padding-top: 10px;
}
.p-t-20 {
  padding-top: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-r-0 {
  padding-right: 0;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-0 {
  margin-bottom: 0;
}
.m-b-8 {
  margin-bottom: 8px;
}
.m-b-16 {
  margin-bottom: 16px;
}
.m-r-8 {
  margin-right: 8px;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.table {
  &.small {
    font-size: 12px;
    //color: #AAAAAA;
  }
  display: table;
  .tr {
    display: table-row;
    .td {
      display: table-cell;
      &.label {
        font-weight: 500;
        padding-right: 6px;
      }
      &.value {
      }
    }
  }
}

.ant-modal-header {
  padding: 16px 45px 16px 24px;
}

.ant-page-header-heading {
  align-items: center;
}

@media (max-width: 991px) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (hover: none) {
  .ant-tooltip {
    display: none;
  }
}
