.scroll-to-validation-error-container {
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: right;

  .ant-alert-with-description {
    margin: 15px;
    border: 1px solid @error-color;
    background-color: @error-color;
    cursor: pointer;
    color: #000000d9;
    box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  }

  .warn-hidden {
    display: none;
  }
}
