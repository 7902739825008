.layout {
  min-height: 100vh;
}

nz-content.with-header {
  padding: 50px 15px 30px;
}

nz-header.common-header {
  background: @white;
  padding-top: 15px;
  padding-bottom: 15px;

  .logo-container {
    height: 50px;
    width: auto;
    display: inline-flex;
    float: left;

    .logo-img {
      width: 100%;
    }
  }

  [nz-menu] {
    line-height: 45px;
    white-space: nowrap;
    padding: 5px 0 0 0;
  }

  .cnt-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.ant-layout-header {
  height: auto;
  padding: 0;
  line-height: 1.5;
  border-bottom: 1px solid @secondary-20-color;
}

.ant-menu-horizontal {
  border-bottom: none;
}

.am-navbar-light {
  color: #000000;
  .am-navbar-right {
    margin-right: 8px;
  }
}

@media (max-width: 767px) {
  nz-content.with-header {
    padding-right: 15px;
    padding-left: 15px;
  }

  nz-header.common-header {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.ant-page-header {
  &.large {
    padding-left: 0;
    padding-top: 0;
    &.p-l-8 {
      padding-left: 8px;
    }

    .ant-page-header-heading-title {
      font-size: 32px;
      font-weight: 500;
      line-height: 1.3;
    }
  }
}

.my-drawer {
  .ant-page-header-heading-title {
    margin-right: 0;
  }

  .am-navbar-left {
    padding-left: 8px;
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  padding: 0 10px;
}
.ant-menu-horizontal > .ant-menu-item::after {
  left: 10px;
  right: 10px;
}
