.ant-progress-bg {
  background-color: @primary-color;
}

.ant-progress-inner {
  background-color: @primary-40-color;
}

.completeness {
  /*
  .ant-progress-bg {
    background: rgb(241,8,8);
    background: -moz-linear-gradient(90deg, rgba(241,8,8,1) 0%, rgba(230,255,2,1) 49%, rgba(0,255,113,1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(241,8,8,1) 0%, rgba(230,255,2,1) 49%, rgba(0,255,113,1) 100%);
    background: linear-gradient(90deg, rgba(241,8,8,1) 0%, rgba(230,255,2,1) 49%, rgba(0,255,113,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f10808",endColorstr="#00ff71",GradientType=1);
  }*/
}
