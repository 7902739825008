.comment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  &__actions {
    min-height: 32px;
  }

  &__text {
    margin-top: 10px !important;
  }

  &__job-opportunity {
    margin-bottom: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
}

.comment-icon {
  &--active {
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      position: absolute;
      top: -2px;
      right: 2px;
      background-color: #F08B5E;
    }
  }
}

@media (hover: none) {
  .comment {
    &__delete-btn {
      opacity: 1;
    }
  }
}
