.card-list {
  padding-top: 10px;

  .header-btn {
    display: flex;
    justify-content: flex-end;
  }

  .ant-picker-range {
    min-width: 100%;
  }

  &--read-only {
    .card-list {
      &__header {
        border-color: #efefef;
      }

      &__body {
        border-color: #efefef;
      }

      &__item {
        cursor: auto;
        &:hover {
          background-color: #ffffff;
        }
      }
    }
  }

  &--yellow {
    .card-list {
      &__header {
        border-color: @border-yellow;
      }
      &__body {
        border-color: @border-yellow;
      }
      &__item {
        background-color: fade(@background-yellow, 30%);
        &:hover {
          background-color: @background-yellow;
        }
      }
    }
  }

  &__header {
    border-top: 1px solid #6FC4C2;
    border-left: 1px solid #6FC4C2;
    border-right: 1px solid #6FC4C2;
    margin: 0;
    padding: 16px 24px;
    border-radius: 8px 8px 0 0;

    & + .card-list__body {
      border-radius: 0 0 8px 8px;
    }
  }

  &__body {
    padding: 24px;
    border: 1px solid #6FC4C2;
    border-radius: 8px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  &__item {
    border-radius: 8px;
    position: relative;
    color: #000000;
    cursor: pointer;
    transition: scale 0.3s, border 0.3s;

    &:hover,
    &:focus-within,
    &:has(.ant-select-focused) {
      background-color: #F9F9F9;

      .card-list {
        &__actions {
          opacity: 1;
        }
      }
    }

    &.invalid {
      border-color: red;
      background-color: rgba(255, 0, 0, 0.1);
    }

    &--active {
      background-color: #EFFAF1;
      &:hover {
        background-color: #EFFAF1;
      }
    }

    & + & {
      margin-top: 20px;
    }
  }

  &__item-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
  }

  &__item-value {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
  }

  &__actions {
    opacity: 0;
    transition: opacity .15s;
    position: absolute;
    right: 6px;
    top: 6px;

    display: flex;
    flex-direction: column;

  }

  &__add-btn {
    margin-bottom: 20px;
  }

  &__form-buttons {
    display: flex;
    justify-content: center;
  }

  &__form-btn {
    & + & {
      margin-left: 10px;
    }
  }

  &__switch-control {
    .ant-form-item-control-input {
      align-items: flex-end;
    }
  }
}

@media (hover: none) {
  .card-list {
    &__actions {
      opacity: 1;
    }
  }
}

.divider-button-wrapper {
  position: relative;

  &__btn {
    &.ant-btn {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      height: 26px;
      width: 26px;
      min-width: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: #6FC4C2;
      border: 2px solid #ffffff;
      color: #ffffff;
      box-shadow: none;

      .anticon-plus {
        width: 14px;
        height: auto;
      }

      &:hover {
        background-color: lighten(#6FC4C2, 7);
      }
    }
  }
}

