.profile__container {

  .v-stepper__content {
    border-right: 1px solid @border-color-split;
  }

  .progress__container {
    padding: 5px 15px 30px 0;
  }

  .ant-steps-vertical > .ant-steps-item {
    padding-bottom: 20px;

    & > .ant-steps-item-container > .ant-steps-item-tail {
      left: 14px;
    }
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: @primary-color;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: @text-color;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: @secondary-20-color;
  }

  .ant-steps-item-icon {
    width: 30px;
    height: 30px;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    width: 30px;
    height: 30px;
    background-color: @secondary-20-color;
    border-color: @secondary-20-color;

    & > .ant-steps-icon {
      color: @text-color;
      font-size: 1rem;
    }
  }

  .ant-steps-item-finish {

    & > .ant-steps-item-container > .ant-steps-item-tail::after {
      background-color: @secondary-20-color;
    }

    & .ant-steps-item-icon {
      border-color: @success-20-color;
      background-color: @success-20-color;

      & > .ant-steps-icon {
        color: @success-100-color;
      }
    }
  }

  .ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
    border-color: @success-20-color;
  }

  .ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title {
    color: @text-color;
    text-decoration: underline;
  }

  .main__content {
    .profile-form {
      padding: 0 0 0 24px;
    }

    .profile-form-buttons {
      text-align: right;
    }
  }

  .ant-page-header {
    padding-top: 0;
  }
}

.mobile_profile__container {
  .ant-btn {
    width: 100%;
  }
  mh-profile-documents-upload {
    .ant-upload.ant-upload-select {
      width: 100%;
    }
  }
  .ant-page-header-heading {
    justify-content: center;
  }
  .col-avatar {
    text-align: center;
  }
}
