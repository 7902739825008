.assessment__container:before {
    content: '';
    background-color: #6fc4c2;
    position: absolute;
    height: 100px;
    width: 100vw;
    top: 0;
    left: 0;
}

.assessment {

  h2, h3 {
    padding: 10px 0;
  }

  .ant-form-item-label > label {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: bolder;

    mh-explanation-label {
      flex: 1 0;
    }
    .question-num {
      flex-basis: 1.6em;
    }
    .question-img-cnt {
      flex-basis: 100%;
      display: flex;
    }
  }

  .ant-form-item-control label {
    font-size: 13px;
    padding: 5px 0;
  }
  .steps-form .ant-form-item .ant-form-item-control {
    padding-left: 30px;
  }

  &__container {
    height: 100%;
    min-height: 100vh;
  }

  &__content {
    max-width: 980px;
    margin: 0 auto;
    padding: 0 15px 15px;
    position: relative;
  }

  &__header {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    min-height: 100px;
  }

  &__logo,
  &__title,
  &__locale-select {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__logo {
    margin-right: 20px;

    .logo-wrapper {
      background-color: #FFFFFF;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      padding: 3px;

      .logo-img {
        width: 64px;
      }
    }
  }

  &__title {
    flex-grow: 1;

    h2 {
      color: #FFFFFF;
      margin-bottom: 0;
    }
  }

  &__locale-select {
    padding-left: 15px;
  }

  .ant-form-item-label {
    .question-img-cnt {
      padding-top: 16px;
    }
  }

  .ant-form > {
    .question-img-cnt {
      padding-bottom: 8px;
      padding-left: 30px;
    }
  }

  .question-img-cnt {
    justify-content: flex-start;

    .question-img {
      width: 100%;
      height: auto;
      max-width: 250px;
      border-radius: 10px;
    }
  }

  .question-num {
    display: inline-block;
    margin-right: 0.5em;
    width: 1.6em;
    height: 1.6em;
    line-height: 1.6em;
    border-radius: 50%;
    text-align: center;
    font-size: 1em;
    background: #EAECF1;
  }

  &__file-input{
    display: none;
  }

  .footer-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .footer-buttons.footer-buttons-right {
    justify-content: flex-end;
  }
}

@media (max-width: 767px) {

  .assessment {

    &__header {
      background-color: #6fc4c2;
      margin-left: -15px;
      margin-right: -15px;
      padding-left: 15px;
      padding-right: 15px;
    }

    .footer-buttons {
      flex-direction: column;
    }

  }
}

.radio-group-vertical,
.checkbox-group-vertical {
  display: flex;
  flex-direction: column;
}


