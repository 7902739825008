.admin-container {
  .sticky-table {
    thead .ant-table-row {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .ant-table-pagination.ant-pagination {
      position: sticky;
      bottom: 0;
      margin: 0;
      padding: 16px 0;
      background: #ffffff;
    }

    .ant-table-content {
      overflow-x: visible;
    }
  }
}

.admin-filters {
  display: flex;
  gap: 12px;

  &__fields {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    flex: 1;
    gap: 10px 12px;
  }

  &__field {
    width: 100%;
    //max-width: 300px;
    nz-select {
      //max-width: 300px !important;
    }
  }

  &__field-group {
    display: flex;
    gap: 12px;
  }

  &__actions {
    display: flex;
    gap: 10px 12px;
    margin-left: auto;
    height: min-content;

    &--talent-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__submit {
    grid-column: span 2;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
}


@media (max-width: 767px) {
  .admin-filters {
    flex-direction: column;

    &__actions {
      margin-left: 0;
      margin-right: auto;
    }
  }
}
