.layout-conversation {
  .layout-conversation-chat {
    height: calc(100dvh - 60px);
  }
  .conversation-left-col {
    display: none;
  }
  @media (min-width: 768px) {
    .layout-conversation-chat {
      height: calc(100svh - 95px);
    }
    .conversation-left-col {
      display: block;
    }
    .chat-mobile-header {
      display: none;
    }
  }
  @media (min-width: 992px) {
    .layout-conversation-chat {
      height: calc(100svh - 165px);
    }
  }
}

.conversation-participant {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  max-height: calc(100vh - 163px);
  overflow: auto;
}

.contact {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  cursor: pointer;

  .contact-name {
    padding-left: 15px;
  }
}

.conversation-list-table {
  .message-preview {
    display: flex;
    align-items: baseline;
  }
}

.conversation-list-table-mobile {
  .ant-table-thead {
    display: none;
  }

  .ant-table.ant-table-small .ant-table-thead > tr > th {
    padding: 0;
    border-bottom: 0;
  }

  .td-conversation-avatar {
    position: relative;

    .conversation-status {
      position: absolute;
      bottom: calc(50% - 35px);
      right: 0;
      border-radius: 50%;
      border: 1px solid white;
      background-color: white;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;

      &-icon {
        display: inline-block;
        border-radius: 50%;
        border: 1px solid @primary-color;
        width: 21px;
        height: 21px;
        line-height: 20px;
        text-align: center;
        color: @primary-color;
      }
    }
  }

  .td-conversation-details {
    max-width: 0;
  }

  .conversation-cell {
    display: flex;
    flex-direction: column;

    .contact {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      &-name {
        padding-left: 0;
        font-weight: 500;
      }
      &-company {
        font-size: 12px;
        font-weight: normal;
        width: 100%;
        display: inline-block;
      }
      &-time {
        font-size: 10px;
        color: @secondary-80-color;
        text-align: right;
      }
    }
    .message-preview {
      color: @secondary-80-color;
      font-size: 12px;
      padding-right: 25px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .conversation-message-counter {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

}
