.mh-avatar {
  background-color: #fafafa;
  background-image: url("/assets/images/avatar-placeholder.svg");

  &--logo {
    background-image: url("/assets/images/logo-placeholder.svg");
    background-size: contain;
    border-radius: 0;

    &:not(:empty) {
      background-image: none;
      background-color: transparent;
    }

    > img {
      object-fit: contain;
      background-color: #fff;
    }
  }
}

.mh-avatar-wrapper {
  position: relative;

  .mh-avatar-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.2);
    color: #ffffff;
    visibility: hidden;
    font-size: 2em;
  }

  &:hover {
    .mh-avatar-overlay {
      visibility: visible;
    }
  }

  .mh-avatar--logo + .mh-avatar-overlay {
    border-radius: 0;
  }

  @media (hover: none) {
    .mh-avatar-overlay {
      visibility: visible;
    }
  }

  .mh-avatar-upload-spin {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 992px) {
  .ant-modal.avatar-picker {
    top: 0;
    max-height: 100dvh;
    overflow: hidden;
    height: -webkit-fill-available;

    .ant-modal-body {
      padding-top: 0;
      padding-bottom: 0;

      image-cropper {
        max-height: calc(100dvh - 120px);
      }
    }
  }
}
