.auth {

  &__container {
    height: 100%;
    min-height: 100vh;

    mh-locale-select {
      position: absolute;
      top: 20px;
      right: 0;
    }

  }

  &__bg {
    position: relative;
  }

  &__bg-img {
    background-image: url("/assets/images/auth-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    min-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 50%;
    bottom: 0;
  }

  &__content {
    max-width: 490px;
    margin: 0 auto;
    padding: 80px 15px 15px;
    position: relative;
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 90px;

    .logo-img {
      width: 85px;
    }
  }

  &__title {
    text-align: center;
    margin-bottom: 10px;
    font-size: 32px;
    font-weight: 500;
  }

  &__back-btn {
    background-color: @white;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 30px;
    left: 30px;
  }

  &__btn {
    width: 100%;
  }

  &__btn-inline {
    padding-left: 80px;
    padding-right: 80px;
  }

  &__form {
    width: 100%;

    &--small {
      max-width: 400px;
    }

    &--center {
      text-align: center;
    }
    &--space-between {
      display: flex;
      justify-content: space-between;
    }
    @media(max-width: 767px) {
      &--space-between {
        flex-direction: column-reverse;
        gap: 25px;
      }
    }
  }

  &__forgot-btn {
    width: 100%;
    text-align: right;
    font-size: 16px;
  }

  &__error-message {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 13px 20px;
    background-color: @error-color;
    color: @white;
  }

  &__stepper {
    width: 100%;
    margin-bottom: 32px;

    .ant-steps-small .ant-steps-item-icon {
      display: none;
    }

    .ant-steps-item-active > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
      color: @primary-color;
      font-weight: bold;
    }

    .ant-steps-item-finish {
      > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
        color: @text-color-secondary;
        font-weight: bold;

        &::after {
          background-color: @process-tail-color;
        }
      }
    }
  }

  &--sign-up {
    .auth {
      &__logo {
        margin-bottom: 40px;
      }
    }
  }

  &--reset-password {
    background-color: @white;
    color: @text-color;

    .ant-btn {
      min-width: 350px;
    }

    .auth {
      &__title {
        font-size: 50px;
        line-height: 1.5;
        font-weight: bold;
        margin-bottom: 30px;
        color: @text-color;
      }

      &__content {
        padding-top: 160px;
        max-width: 950px;
      }

      &__text {
        font-size: 22px;
        margin-bottom: 30px;
        text-align: center;
      }

      &__logo-wrap {
        margin-bottom: 50px;

        .logo-img {
          width: 145px;
        }
      }
    }
  }
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item {
  line-height: 48px;
}

.ant-divider-horizontal.ant-divider-with-text {
  margin: 30px 0;
}

@media (max-height: 900px) {

  .auth {

    &__content {
      padding-top: 60px;
    }

    &__logo {
      margin-bottom: 40px;
    }

    &--reset-password {

      .auth {
        &__content {
          padding-top: 90px;
        }
      }
    }
  }
}

@media (max-width: 767px) {

  .auth {
    background: linear-gradient(to bottom, @secondary-20-color 300px, @layout-body-background 300px);

    &::before {
      content: '';
      position: absolute;
      top: 235px;
      bottom: 0;
      left: 0;
      right: 0;
      background: @layout-body-background;
      border-radius: 20px 20px 0 0;
    }

    &__content {
      padding-top: 75px;
    }

    &__logo {
      margin-bottom: 100px;
    }

    &__title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    &__error-message {
      top: auto;
      bottom: 0;
    }

    &__stepper {
      margin-bottom: 20px;
    }

    &--sign-up {
      background: @layout-body-background;

      &::before {
        content: none;
      }

      .auth {
        &__content {
          padding-top: 55px;
          position: relative;
        }

        &__title {
          margin-bottom: 20px;
        }
      }
    }

    &--reset-password {
      background: linear-gradient(to bottom, @secondary-20-color 300px, @layout-body-background 300px);
      color: @text-color;

      .ant-btn {
        min-width: 250px;
      }

      .auth {

        &__title {
          font-size: 24px;
          color: @text-color;
          margin-bottom: 20px;
        }

        &__logo {
          margin-bottom: 100px;

          .logo-img {
            width: 85px;
          }

          &-wrap {
            margin-bottom: 0;
          }
        }

        &__content {
          padding-top: 75px;
        }

        &__text {
          font-size: 16px;
          margin-bottom: 30px;
        }

        &__btn-inline {
          width: 100%;
        }
      }
    }
  }
}
