.widget {
  padding: 18px 15px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  text-align: right;
  display: flex;
  flex-direction: column;
  height: 100%;

  &--link {
    cursor: pointer;
  }

  &__label {
    font-size: 16px;
    font-weight: 400;
  }

  &__value {
    font-size: 90px;
    font-weight: 700;
    line-height: 90px;
  }

  &__icon {
    display: flex;
    position: absolute;
    opacity: .2;
  }

  &__list {
    position: relative;
    margin-top: auto;
  }

  &__list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    padding: 4px 9px;
    transition: all .3s;

    &:hover {
      background: rgba(255, 255, 255, 0.15);
    }
  }

  &__list-item-label {
    text-align: left;
    color: #ffffff;
  }

  &__list-item-value {
    white-space: nowrap;
  }

  &__more-btn {
    margin-top: 10px;

    &:hover {
      background-color: #ffffff !important;
      border-color: #ffffff !important;
      color: @primary-color !important;
    }
  }
}
