.profile-card {
  position: relative;
  width: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 30px;
    left: 0;
    bottom: 0;
    width: 1px;
    background-color: #EFEFEF;
    z-index: 1;
  }

  &--read-only {
    .profile-card {
      &__header {
        border-color: #efefef;
      }

      &__body {
        border-color: #efefef;
      }

      &__item {
        cursor: auto;
      }
    }
  }

  &__header {
    margin: 0;
    padding: 16px 24px;
  }

  &__body {
    padding: 24px;
    margin-left: 24px;
    border: 1px solid #6FC4C2;
    border-radius: 8px;
  }

  &__title {
    color: #6FC4C2;
    font-size: 16px;
    font-weight: 500;
    margin: 0;
    position: relative;

    &:before {
      content: '';
      border: 2px solid #6fc4c2;
      background: #fff;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 7px;
      left: -24px;
      transform: translateX(-46%);
      z-index: 1;
    }
  }

  &__subtitle {
    color: #AAAAAA;
    font-weight: 400;
  }

  &__item {
    border-radius: 8px;
    position: relative;
    color: #000000;
    cursor: pointer;
    transition: scale 0.3s, border 0.3s;

    & + & {
      margin-top: 20px;
    }
  }

  &__item-label {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

  }

  &__item-value {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    .duration-range {
      color: #AAAAAA;
    }
  }

  &__actions {
    opacity: 0;
    transition: opacity .15s;
    position: absolute;
    right: 6px;
    top: 6px;

    display: flex;
    flex-direction: column;

  }

  &__add-btn {
    margin-bottom: 20px;
  }

  &__form-buttons {
    display: flex;
    justify-content: center;
  }

  &__form-btn {
    & + & {
      margin-left: 10px;
    }
  }

  &__switch-control {
    .ant-form-item-control-input {
      align-items: flex-end;
    }
  }
}

@media (hover: none) {
  .profile-card {
    &__actions {
      opacity: 1;
    }
  }
}

.divider-button-wrapper {
  position: relative;

  &__btn {
    &.ant-btn {
      position: absolute;
      right: 24px;
      top: 50%;
      transform: translateY(-50%);
      height: 26px;
      width: 26px;
      min-width: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: #6FC4C2;
      border: 2px solid #ffffff;
      color: #ffffff;
      box-shadow: none;

      .anticon-plus {
        width: 14px;
        height: auto;
      }

      &:hover {
        background-color: lighten(#6FC4C2, 7);
      }
    }
  }
}

@media (max-width: 991px) {
  .profile-card {
    &__body {
      padding: 0;
      border: none;
    }
  }
}
