mh-messages-conversation-chat {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .scrollable {
    overflow: auto;
    overscroll-behavior: none;
    flex: 1;
    scrollbar-face-color: @divider-color;
    scrollbar-track-color: @secondary-60-color;
    position: relative;
  }
  .message-list {
    position:absolute;
    bottom:0;
    right:0;
    left:0;
    max-height:100%;
    z-index: 0;
  }
  .message-list,
  .message-list-footer {
    padding-left: 15px;
    padding-right: 15px;
  }

  .message-list {
    .ant-comment {
      .ant-comment-inner {
        padding-top: 5px;
        padding-bottom: 5px;

        .ant-comment-content {
          display: flex;
          flex-direction: column-reverse;
        }

        .ant-comment-content-author-time {
          font-size: 11px;
        }

        .ant-comment-avatar {
          margin: 0;
        }

        .message-balloon {
          word-break: break-word;
          white-space: pre-wrap;
          max-width: 100%;
          text-align: left;
          float: left;
          padding: 5px 10px;
          border-radius: 10px;
          border-top-left-radius: 0;
          background-color: #6FC4C2;
          color: #FFFFFF;
          & > a {
            display: inline-block;
          }
          &.video, &.image, &.audio {
            padding: 0;
            line-height: 0;
          }

          &.video {
            .video-title {
              position: absolute;
              background-color: black;
              color: #FFFFFF;
              height: 20px;
              line-height: 20px;
              padding: 0 5px;
            }
          }

          &.audio {
            background-color: transparent;
          }

          .attachment-image, .attachment-video {
            max-height: 250px;
            min-height: 250px;
            max-width: 80vw;
            display: inline-block;
            border-radius: 0 7px 7px 7px;
            margin: 0 0 0 -4px;
          }

          .attachment-audio {
            margin: 5px 5px 5px 1px;
          }

          &.uploading {
            color: #7A7A7A;
            nz-spin {
              display: inline-block;
              margin-right: 7px;
              position: relative;
              top: 2px;
            }
          }
        }
      }
    }
  }

  .message-list-footer {
    border-top: 1px solid #d9d9d9;
    padding-top: 15px;
    padding-bottom: 5px;

    .message-input-cnt {
      display: flex;
      align-items: center;
      gap: 10px;

      .ant-form-item {
        margin-bottom: 0;
      }

      >*:not(:first-child) {
        flex: 1;
      }

      >*:nth-child(1) {
        width: 100%;
      }

      .btn-send {
        min-width: 35px;
      }
    }
    .message-add-media-cnt {
      display: flex;
      align-items: baseline;
      gap: 3px;
    }
  }

  .ant-comment:not(.own-message) {
    .ant-comment-inner {
      .message-balloon {
        a {
          color: #FFFFFF;
          text-decoration: underline;
        }

        .audio::-webkit-media-controls-panel {
          background-color: #6FC4C2;
        }
      }
    }
  }

  //own message
  .ant-comment.own-message {
    .ant-comment-inner {
      flex-direction: row-reverse;

      .message-balloon {
        float: right;
        background-color: #EFEFEF;
        color: #000000;
        border-top-right-radius: 0;
        border-top-left-radius: 10px;

        .attachment-image, .attachment-video {
          border-radius: 7px 0 7px 7px;
        }
      }

      .ant-comment-content {
        text-align: right;
        .ant-comment-content-author {
          justify-content: flex-end;
          & > a, & > span {
            padding-right: 0;
            padding-left: 8px;
          }
        }
      }
    }
  }

  //system message
  .ant-comment.system-message {
    .ant-comment-inner {
      .ant-comment-content {
        .ant-comment-content-author {
          justify-content: center;
        }

        .ant-comment-content-detail {
          display: flex;
          justify-content: center;
        }

        .message-balloon {
          background-color: @danger-40-color;
          color: #fff;
          border-radius: 10px;
        }
      }
    }
  }
  //system message: profile-access
  .ant-comment.action-message {
    .ant-comment-inner {
      .ant-comment-content {
        .message-balloon {
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: @info-color-deprecated-bg;
          border: 1px solid @info-color-deprecated-border;
          color: #000000d9;

          &-actions {
            padding-left: 10px;
          }
        }
      }
    }
  }
  //system message: agreement-reached
  .ant-comment.agreement-message {
    .ant-comment-inner {
      .ant-comment-content {
        .message-balloon {
          background-color: transparent;
          color: #000000d9;
        }
      }
    }
  }
}

//conversation header
navbar.chat-mobile-header.fixed{
  position: fixed;
  top: 0;
  z-index: 1;
  height: 60px;
  border-bottom: 1px solid #EFEFEF;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;

  .am-navbar-left, .am-navbar-title {
    flex: 0;
  }

  .am-navbar-right {
    color: @primary-color;
  }

  .contact-name {
    font-size: 12px;
    margin-left: 10px;
  }
}

.agreement-alert {
  .ant-alert {
    padding: 4px 15px;
  }
}

@media (max-width: 767px) {
  mh-messages-conversation-chat {
    border: 0;
    margin: -16px;

    .ant-comment.action-message {
      .ant-comment-inner {
        .ant-comment-content {
          .message-balloon {
            flex-direction: column;
            align-items: flex-end;
            &-actions {
              padding: 5px 0;
            }
          }
        }
      }
    }
  }
}

body.chat-open {
  overflow: hidden;
  height: 100dvh;
  position: fixed;
}
