.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional),
.required-text {
  &::before {
    content: none;
  }

  &::after {
    display: inline;
    margin-right: 4px;
    color: @label-color;
    font-size: inherit;
    font-family: SimSun, sans-serif;
    line-height: 1;
    vertical-align: super;
    content: '\2217' !important;
  }
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
  &:has(.explanation-label) {
    &::after {
      content: none !important;
    }

    .explanation-label__content {
      &::after {
        content: '\2217';
        display: inline;
        margin-left: 2px;
        color: @label-color;
        font-size: inherit;
        font-family: SimSun, sans-serif;
        line-height: 1;
        vertical-align: super;
      }
    }
  }
}

.ant-form-item-label > label {
  height: auto;
  font-size: 12px;
  display: inline-block;
}

.ant-col-24.ant-form-item-label {
  padding: 0 0 4px;
}

.ant-form-item-explain {
  font-size: 12px;
}

[nz-checkbox].ng-invalid.ng-dirty .ant-checkbox-inner {
  border-color: @error-color;
}

.btn-secondary {
  background-color: @secondary-color;
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.02);
  border-color: @secondary-color;

  &:hover, &:focus {
    background-color: lighten(@secondary-color, 10%);
    border-color: lighten(@secondary-color, 10%);
  }
}

.ant-input-lg {
  padding: 11px 12px;
  font-size: 16px;
  line-height: 26px;
}

.ant-input-affix-wrapper-lg > input.ant-input-lg {
  padding: 5px 0 4px;
}

.password-tip-box {
  margin: 3px 2px;

  &--bg {
    background-color: @white;
    border-radius: 8px;
    padding: 5px;
  }
}

.password-tip {
  font-size: 12px;
  color: @text-color-secondary;
  &--success {
    color: @primary-color;
  }
  &--error {
    color: @error-color;
  }
}

.upload-file {
  &--error {
    .ant-upload.ant-upload-drag {
      border-color: @error-color;
    }
  }
}

@media (max-width: 991px) {
  nz-date-picker {
    .ant-picker-clear {
      display: none;
    }

    &.ant-picker-focused {
      .ant-picker-clear {
        display: block;
        opacity: 1;
      }
    }
  }
}

date-header .ant-picker-header-view {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}

date-header .ant-picker-header-view .ant-picker-header-month-btn {
  margin-left: 0;
  margin-right: 8px;
}
