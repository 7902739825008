// -------- import official ng-zorro less file -----------
@import '../../../../../node_modules/ng-zorro-antd-mobile/src/ng-zorro-antd-mobile.less';
@import "../../../../../node_modules/ng-zorro-antd/ng-zorro-antd.less";

@import "color-variables";
@import "common-header";
@import "element/form-controls";
@import "element/auth";
@import "element/button";
@import "element/main";
@import "element/talent-profile";
@import "element/avatar";
@import "element/talent-profile-preview";
@import "element/slider";
@import "element/progress";
@import "element/card-list";
@import "element/profile-card";
@import "pages/job-offer";
@import "pages/layout-conversation";
@import "pages/admin";
@import "element/chat";
@import "element/widget";
@import "element/scroll-to-validation-error";
@import "element/comments";
@import "element/alerts";
@import "pages/assessment";

@supports (-webkit-touch-callout: none) { //for iOS to prevent zoom on field touch
  select,
  textarea,
  input,
  nz-select-item,
  button {
    font-size: 16px!important;
  }
}

.hide-dropdown {
  display: none;
}

