.job-offer {
  border: 1px solid #6fc4c2;
  border-radius: 8px;
  padding: 15px;

  &--in-list {
    border: none;
    border-radius: 0;
    padding: 0 20px 0 0;

    .job-offer__description {
      flex: 1 0 calc(100% - 20px);
    }
  }

  &__header {
    margin-bottom: 15px;
  }

  &__header-row {
    display: flex;
    gap: 15px;
    align-items: flex-start;
  }

  &__header-col {
    &--right {
      margin-left: auto;
      text-align: right;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;

    color: @text-color;

    a {
      color: currentColor;
    }
  }

  &__info-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
  }

  &__info-value {
    display: flex;
    align-items: center;
    gap: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: @secondary-100-color;

    & + & {
      &:before {
        content: "•";
        display: flex;
        font-size: 24px;
        line-height: 8px;
        color: @text-color-secondary;
      }
    }
  }

  &__info-value-body {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__salary {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__salary-check {
    display: flex;
  }

  &__salary-value {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;

    color: @text-color;
  }

  &__field {
    & + & {
      margin-top: 16px;
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
  }

  &__value {
    font-size: 14px;
    font-weight: 400;
  }

  &__description-wrap {
    display: flex;
  }

  &__description {
    overflow: hidden;
    white-space: pre-line;

    &--h-auto {
      height: auto !important;
    }
  }

  &__more {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }

  &__more-btn {
    transition: .5s all;
    &--active {
      transform: rotateX(180deg);
    }
  }
}

.matches-list {
  &__pagination {
    text-align: right;
    margin-top: 16px;
  }
}

.matches-item {
  border: 1px solid #efefef;
  border-radius: 8px;
  padding: 15px;

  & + & {
    margin-top: 16px;
  }

  &--favourite {
    background: #F8FDFD;
  }

  &__header {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
    align-items: flex-start;
  }

  &__header-col {
    &--right {
      margin-left: auto;
      text-align: right;
    }
  }

  &__avatar {
    position: relative;
  }

  &__status {
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: @layout-body-background;
    border: 1px solid @primary-color;
    border-radius: 50%;
    display: flex;
    align-items: center;

    &:empty {
      display: none;
    }
  }

  &__name {
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;

    color: @text-color;

    a {
      color: currentColor;
    }
  }

  &__job-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
  }

  &__info-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0 10px;
  }

  &__info-value {
    display: flex;
    align-items: center;
    gap: 10px;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: @secondary-100-color;

    & + & {
      &:before {
        content: "•";
        display: flex;
        font-size: 24px;
        line-height: 8px;
        color: @text-color-secondary;
      }
    }
  }

  &__info-value-body {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__salary {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__salary-check {
    display: flex;
  }

  &__salary-value {
    font-weight: 600;
    font-size: 20px;
    line-height: 150%;

    color: @text-color;
  }

  &__score {
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }

  &__score-label {
    font-weight: 600;
    font-size: 14px;
    line-height: 40px;

    color: @text-color;
  }

  &__score-value {
    padding: 8px;
    border: 2px solid @primary-color;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;

    font-weight: 600;
    font-size: 20px;
    line-height: 40px;
    color: @primary-color;
  }

  &__body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px 20px;
  }

  &__label {
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;

    color: @text-color;
  }

  &__value {
    .ant-tag {
      color: @text-color;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.location {
  &__value {
    & + & {
      &:before {
        content: '; ';
      }
    }
  }
}

.job-offer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 0 8px;

  &__filters {
    margin-left: auto;
  }
}

.job-offer-form {
  gap: 10px 0;

  &__filter {
    align-items: center;
  }

  &__matches-filter {
    min-width: 141px;
  }

  &__sort-by {
    min-width: 196px;
  }

  &__edit-btn {
    margin-right: 10px;
  }
}

.ant-tag {
  margin-bottom: 4px;
}

@media (max-width: 767px) {
  .job-offer-header {
    &__filters {
      width: 100%;
    }
  }

  .job-offer {
    &__header-row {
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }

    &__header-col {
      &--right {
        margin-left: 0;
        text-align: center;
      }
    }

    &__name {
      text-align: center;
    }

    &__info-row {
      flex-direction: column;
    }

    &__info-value {
      & + & {
        &:before {
          content: none;
        }
      }
    }

    &__description-wrap {
      display: block;
    }

    &__more {
      margin-top: 0;
    }
  }

  .matches-item {
    &__header {
      flex-direction: column;
      align-items: stretch;
      text-align: center;
      position: relative;
    }

    &__body {
      grid-template-columns: 1fr;
    }

    &__info-row {
      flex-direction: column;
      margin-top: 15px;
    }

    &__info-value {
      & + & {
        &:before {
          content: none;
        }
      }
    }

    &__avatar {
      align-self: center;
    }

    &__salary {
      justify-content: center;
      color: @secondary-100-color;
      position: relative;
      display: inline-flex;
    }

    &__salary-check {
      display: flex;
      position: absolute;
      left: -21px;
    }

    &__score {
      position: absolute;
      top: 0;
      right: 0;
      flex-direction: column-reverse;
      gap: 0;
    }

    &__score-label {
      line-height: 24px;
    }
  }

  .job-offer-create {
    width: auto;
  }

  .job-offer-form {
    align-items: center;
    &__filter {
      margin-right: 0 !important;

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        padding: 0;
      }
    }

    &__matches-filter {
      min-width: 0;
    }

    &__sort-by {
      min-width: 0;
    }

    &__edit-btn {
      order: 1;
      margin-right: 5px;
      margin-top: 2px;
    }

    &__back-btn {
      order: -2;
      margin-right: auto;
    }

    &__search-btn {
      order: -2;
      margin-right: auto;
    }
  }

  nz-page-header-extra {
    width: 100%;
  }
}
